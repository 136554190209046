<template>
  <div>
    <b-card no-title>
      <b-row>
        <b-col
            xs="12"
            class="mx-auto"
        >
          <b-card-title>
            {{ $t('card.title.modify.role') }}
            <div v-if="this.$acl.canDeletePermission(role)" class="float-right mr-1">
              <b-button
                  variant="danger"
                  size="sm"
                  v-b-tooltip.hover
                  :title="$t('label.button.delete')"
                  @click="deleteEntity(formData.deletable)"
              >
                <span v-if="!this.$helpers.isMobileResolution() && !this.$helpers.isSmallResolution()"
                      class="text-nowrap">{{ $t('label.button.delete') }}</span>
                <feather-icon v-else icon="XCircleIcon"/>
              </b-button>
            </div>
          </b-card-title>
        </b-col>
      </b-row>
      <b-card-text>
        {{ $t('card.description.modify.role') }}
      </b-card-text>
    </b-card>

    <b-card-code>
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group
                  :label="$t('form.name')"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.name').toLowerCase()"
                    rules="required"
                    vid="name"
                >
                  <b-form-input
                      v-model="formData.name"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('form.name').toLowerCase()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                  :label="$t('form.roles')"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.roles').toLowerCase()"
                    rules="required"
                    vid="permissions"
                >
                  <v-select
                      v-model="formData.permissions"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="permissionOptions"
                      multiple
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- submit button -->
            <b-col class="mt-1">
              <b-button
                  variant="primary"
                  type="submit"
                  class="float-right"
                  @click.prevent="submitForm"
              >
                {{ $t('label.button.save') }}
              </b-button>
              <cancel-and-g-o-t-o-previous-page class="mr-2"/>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <b-card-code>
      <tabs
          ref="tabs"
          :entity-id="parseInt(this.$route.params.id)"
      />
    </b-card-code>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BCard, BCardTitle, BCardText, BRow, BCol, BButton, BFormCheckbox, VBTooltip,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import vSelect from 'vue-select'
import tabs from '@/views/pages/_components/_tabs/_role/update.vue'
import cancelAndGOTOPreviousPage from '@/views/pages/_components/_helper/cancelAndGOTOPreviousPage'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BCard,
    BCardTitle,
    BCardText,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    vSelect,
    tabs,
    cancelAndGOTOPreviousPage,
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data() {
    return {
      role: 'role',
      formData: {
        id: null,
        name: '',
        permissions: '',
        deletable: false
      },
      permissionOptions: [],
      required,
    }
  },
  created() {
    this.$store.dispatch('fetchRole', this.$route.params.id).then(response => {
      this.formData.id = this.$route.params.id
      this.formData.name = response.data.entity.name
      this.formData.guard_name = response.data.entity.guard_name
      this.formData.deletable = response.data.entity.deletable

      this.$store.dispatch('fetchPermissionsForSelect').then(plResponse => {
        this.permissionOptions = plResponse

        if (response.data.entity.permissions && response.data.entity.permissions.length) {
          const items = []

          Object.keys(response.data.entity.permissions).forEach(key => {
            Object.keys(this.permissionOptions).forEach(optionKey => {
              if (response.data.entity.permissions[key].id === this.permissionOptions[optionKey].value) {
                items.push(this.permissionOptions[optionKey])
              }
            })
          })

          if (items.length) {
            this.formData.permissions = items
          } else if (this.permissionOptions.length === 1) {
            // eslint-disable-next-line prefer-destructuring
            this.formData.permissions = this.permissionOptions[0]
          } else {
            this.formData.permissions = ''
          }
        }
      })
    })
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let fd = {...this.formData}

          const items = []
          Object.keys(this.formData.permissions).forEach(key => {
            items.push(this.formData.permissions[key].value)
          })

          fd.permissions = items

          this.$store.dispatch('updateRole', fd).then(() => {
            this.$helpers.showSuccessToast()

            this.$acl.canListPermission(this.role)
                ? this.$router.push({name: 'roles'})
                : this.$router.go(-1)
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          })
        }
      })
    },
    deleteEntity(deletable) {
      if (deletable) {
        this.$bvModal
            .msgBoxConfirm(this.$t('confirm.message.delete'), {
              title: this.$t('modal.title.confirm'),
              size: 'sm',
              okVariant: 'primary',
              okTitle: this.$helpers.ucFirst(this.$t('label.yes')),
              cancelTitle: this.$helpers.ucFirst(this.$t('label.no')),
              cancelVariant: 'outline-secondary',
              hideHeaderClose: false,
              centered: true,
            })
            .then(value => {
              if (value) {
                const id = this.$route.params.id
                this.$store.dispatch('deleteRole', id).then(() => {
                  this.$helpers.showSuccessToast(this.$t('text.api.response.deleteSuccessfully', {id}))

                  this.$acl.canListPermission(this.role)
                      ? this.$router.push({name: 'users'})
                      : this.$router.go(-1)
                })
              }
            })
      } else {
        this.$helpers.showErrorToast(this.$t('text.api.response.cantDelete'))
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
